@import '../../../styles/mixins';

.Description {
	line-height: 1.8rem;
}

.List {
	@include flex(column, flex-start, flex-start);
	gap: 0.5rem;
	list-style: none;
}

.List__Item {
	@include flex(row, flex-start, flex-start);
	gap: 1rem;
	padding-left: 4px;

	& > div {
		line-height: 1.8rem;
	}
}

.List__Item__Point {
	display: flex;
	min-width: 0.4rem;
	height: 0.4rem;
	aspect-ratio: 1;
	border-radius: 50%;
	background-color: var(--background-color-border-lighter);
	margin-top: 0.6rem;
	transition: background-color var(--transition-duration) ease-in-out;
}

.List__Item__FirstWord {
	gap: 0.5rem;
	font-weight: 700;
}

.List__Item__Content {
	font-weight: 400;
}
