@import '../../../styles/mixins';

.Container {
	@include flex(column, flex-start, flex-start);
	gap: 1rem;
}

.Hyperlinks__container {
	@include flex(column, flex-start, flex-start);
	gap: 1rem;
}
