@import '../../../styles/mixins';

.Text {
	display: inline;
}

.Additional {
	color: var(--text-color-lower);
	font-weight: 800;
	padding-left: 0.4rem;
}
