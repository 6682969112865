@import '../../../styles/mixins';

.Container {
	@include flex(row, space-between, center);
}

.Name {
	// remove default a tag styles
	text-decoration: none;
	color: var(--text-color);

	h1 {
		font-size: 1.25rem;
		font-weight: 700;
		transition: all var(--transition-duration) ease-in-out;
	}
}

.Menu {
	@include flex(row, flex-start, center);
	gap: 0.6rem;
}

.Menu__item {
	cursor: pointer;
	padding: 0.6rem;
	transition: all var(--transition-duration) ease-in-out;

	&:hover {
		transform: scale(1.1);
	}

	.Menu__item__button {
		position: relative;
		@include flex(row, center, center);
		gap: 0.3rem;

		// remove default button styles
		cursor: pointer;
		border: none;
		background: none;
		outline: none;
		color: var(--text-color);
		font-size: 0.9rem;
		font-weight: 700;
		text-transform: uppercase;

		p {
			font-family: 'Nunito', sans-serif;
			transition: all var(--transition-duration) ease-in-out;
		}
	}
}

.Icon__moon__animation__hide,
.Icon__sun__animation__hide {
	transform: scale(0);
	opacity: 0;
}

.Icon__sun {
	position: absolute;
}
