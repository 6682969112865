@font-face {
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 400;
	src: url('./fonts/ProximaNova/proximanova_regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 700;
	src: url('./fonts/ProximaNova/proximanova_bold.otf') format('opentype');
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

html {
	scroll-behavior: smooth;
	background-color: var(--background-color);
}

body {
	margin: 0;
	font-family: 'Proxima Nova', 'Nunito', 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.global-icons {
	color: var(--text-color);
	transition: all var(--transition-duration) ease-in-out;
}
