@import '../../../styles/mixins';

.Tag {
	@include flex(row, center, center);
	color: var(--text-color);
	text-decoration: none;
	font-size: 1rem;
	font-weight: 300;
	gap: 0.5rem;

	&:hover {
		text-decoration: underline;
	}
}

.Icon {
	color: var(--text-color-lower);
}
