@import '../../../styles/mixins';

.Container {
	@include flex(column, flex-start, flex-start);
	gap: 3rem;
	padding-top: 2rem;
	margin-top: 6rem;
	width: 100%;

	&:first-child {
		margin-top: -2rem;
	}
}
