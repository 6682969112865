@import '../../../styles/mixins';

.Title {
	@include flex(row, flex-start, center);
	width: 100%;
	font-size: 2rem;
	gap: 1rem;

	&::after {
		content: '';
		width: 100%;
		height: 2px;
		background-color: var(--background-color-border-lighter);
	}
}
