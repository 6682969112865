@import './styles/colors';
@import './styles/const';

.app {
	position: relative;
	z-index: 2;
	background-color: var(--background-color);
	color: var(--text-color);
	transition: background-color var(--transition-duration) ease-in-out, color var(--transition-duration) ease-in-out;
}

header,
#main-section,
#about-section {
	max-width: 1024px;
	padding: 1.5rem;
	margin: auto;
}

.tempStyle {
	color: var(--text-color);
}
