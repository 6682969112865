@import '../../../styles/mixins';

.MainContainer {
	@include flex(column, flex-start, flex-start);
	gap: 1.5rem;
}

.SocialButtonsContainer {
	@include flex(row, center, center);
	gap: 1rem;
	margin-bottom: 1rem;
}

.Container__Inner {
	padding: 3rem 2rem 3rem 2rem;
	@include flex(row, space-between, flex-start);
	gap: 3rem;
	border-radius: 0.5rem;

	background-color: var(--background-color-lighter);
	transition: background-color var(--transition-duration) ease-in-out;
	box-shadow: var(--cards-shadow);

	@media (width < 768px) {
		@include flex(column, flex-start, flex-start);
	}
}

.Navigation {
	@include flex(column, flex-start, flex-start);
	width: 30%;
	height: 100%;

	list-style: none;
	position: sticky;
	top: 2rem;

	@media (width < 768px) {
		position: static;
	}
}

.Content {
	@include flex(column, flex-start, flex-start);
	width: 70%;
}

@media (width < 768px) {
	.Navigation,
	.Content {
		width: 100%;
	}
}

.NavigationItem {
	margin-top: 4rem;
}
