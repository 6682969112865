@import '../../../styles/mixins';

.Container {
	@include flex(column, center, center);
	gap: 1rem;
}

.ProfilePicContainer {
	position: relative;
	width: 15rem;
	height: 15rem;
	border-radius: 50%;
	overflow: hidden;

	box-shadow: var(--cards-shadow);
	transition: box-shadow var(--transition-duration) ease-in-out;

	&:hover {
		box-shadow: 0 0 2px 0px var(--background-color), 0 0 0 5px var(--contrast-background-color);

		.ProfilePic {
			transform: rotateY(180deg); // Flip animation
		}

		.ProfilePic__First {
			z-index: 1;
		}

		.ProfilePic__Second {
			z-index: 2;
		}
	}
}

.ProfilePic {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	object-fit: cover;
	border: 4px solid var(--background-color);
	box-shadow: 0 0 0 6px var(--background-color);
	transition: all var(--transition-duration) ease-in-out;
}

.ProfilePic__First {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
}

.ProfilePic__Second {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}

.Title {
	font-size: 1.5rem;
	font-weight: 700;
	margin-bottom: 1rem;
	text-decoration: underline;
}

.Description {
	font-size: 1.1rem;
	font-weight: 400;
	line-height: 1.8rem;
	text-align: center;
	margin-bottom: 1rem;
}
