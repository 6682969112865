@import '../../../styles/mixins';

.NavigationList {
	@include flex(column, flex-start, flex-start);
	gap: 1rem;
	list-style: none;
	width: 100%;
}

.NavigationItem {
	@include flex(row, space-between, center);
	gap: 1rem;
	width: 100%;
	min-width: 150px;
	padding: 0.6rem 1rem;
	background-color: var(--background-color-lighter);
	border-radius: 0.5rem;
	border: 1px solid var(--background-color-border-lighter);

	cursor: pointer;
	font: 600 1rem 'Nunito', sans-serif;
	color: var(--text-color);
	transition: background-color var(--transition-duration) ease-in-out, border var(--transition-duration) ease-in-out;

	&:hover {
		background-color: var(--background-color-lighter-hover);
	}
}
