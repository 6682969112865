$primary-color-light: #f9f9f8;
$secondary-color-light: #fafaf7;
$text-color-light: #20110e;
$text-color-lower-light: #d1ab49;
$category-color-light: #ffffff;
$category-background-color-light: #486f41;

$background-color-light: #f9f9f8;
$background-color-lighter-light: #ffffff;
$background-color-border-lighter-light: #eaeaea;
$background-color-lighter-light-hover: #fcfbfa;
$contrast-background-color-light: #241511;

:root[data-theme='theme-light'] {
	--background-color: #{$background-color-light};
	--background-color-lighter: #{$background-color-lighter-light};
	--background-color-border-lighter: #{$background-color-border-lighter-light};
	--background-color-lighter-hover: #{$background-color-lighter-light-hover};
	--contrast-background-color: #{$contrast-background-color-light};
	--primary-color: #{$primary-color-light};
	--secondary-color: #{$secondary-color-light};
	--text-color: #{$text-color-light};
	--text-color-lower: #{$text-color-lower-light};
	--category-color: #{$category-color-light};
	--category-background-color: #{$category-background-color-light};
}
